<template>
  <div>
    <div class="pop-up-bg" @click.prevent="$emit('close', false)"></div>
    <div class="pop-up">
      <a
        class="pop-up__close icon icon-close"
        @click.prevent="$emit('close', false)"
      ></a>
      <h2 v-if="data.mod == 'tasks'">{{$root.langText.directory.quotes}} {{$root.langText.from}} {{data.info.created_at | date}}</h2>
      <h2 v-if="data.mod == 'history'">{{titleFact ? titleFact : 'Звонок'}} {{$root.langText.from}} {{data.info.created_at | date}}</h2>


      <div style="overflow-y: auto;max-height: 60vh;" v-if="data.mod == 'tasks'">


        <div class="form__input form__input--not-focus">
          <input type="text" v-model="creator" readonly />
          <span>{{$root.langText.changes.create}}</span>
        </div>

        <div class="form__input form__input--not-focus" v-if="base">
          <input type="text" v-model="base" readonly />
          <span>{{$root.langText.changes.basis}}</span>
        </div>

        <div class="form__input">
          <select v-model="typeTask" @change="changeType(typeTask)">
            <option
              v-for="(type, index) in typeTaskList"
              :key="index"
              :value="type.id"
            >
              {{ type.name }}
            </option>
          </select>
          <span>{{$root.langText.input.taskType}}</span>
          <div class="icon icon-arrow-down"></div>
        </div>


        <div class="form__input">
          <select v-model="typeTaskContact">
            <option
              v-for="(type, index) in typeTaskContactList"
              :key="index"
              :value="type.id"
            >
              {{ type.name }}
            </option>
          </select>
          <span>{{$root.langText.contacts.contactsView}}</span>
          <div class="icon icon-arrow-down"></div>
        </div>


        <Autocomplete
          :data="{
            name: $root.langText.contacts.contactPerson,
            mod: 'contact',
            key: 'contact',
            input: contact,
            inputId: contact_id,
            idClient: idClient,
            typeClient: 'client',
          }"
          @result="autocompleteResult"
        />

        <!-- <div class="form__input">
          <select v-model="fields.status_id">
            <option v-for="(type, index) in status" :key="index" :value="type.id">
              {{ type.name }}
            </option>
          </select>
          <span>Важность</span>
          <div class="icon icon-arrow-down"></div>
        </div> -->

        <div class="form__row" style="justify-content: space-between;overflow: hidden;">
          <span
            style="
              font-size: 12px;
              color: #8eb6e3;
              font-weight: 600;
              line-height: 2.3;
              width: 80%;
            "
            >{{$root.langText.input.taskDate}}</span
          >
          <DataInput
            :data="{
              data: date,
              input: 'date',
              mod: 'dataTime',
              min: new Intl.DateTimeFormat('ru').format(new Date()),
            }"
            @resultData="dataInput"
          />
        </div>

        <div class="form__input">
          <p class="form__input__textarea-p">{{ description }}</p>
          <textarea class="form__input__textarea" :style="error ? 'border-color:#FF3300': ''" v-model="description" />
          <span :style="error ? 'color:#FF3300': ''">{{$root.langText.input.description}}</span>
        </div>

        <!-- доп -->
        <h2 style="position: relative">{{$root.langText.input.participantsExtra}}
          <a href="#" class="title__plus" @click.prevent="team = true">+</a>
        </h2>

        <Team :popup="team" @close="closeList" v-if="team"/>

        <div class="form__input"
          style="width: 97%;margin-bottom:5px"
          v-for="(item, index) in list"
          :key="index"
        >
          <input type="text" style="border:none;font-size:14px" :data-id="item.manager_id" v-model="item.manager_name" readonly />
          <a class="form__close date" @click.prevent="list.splice(index, 1)"></a>
        </div>
        <!-- end доп -->

        <div v-if="btn">
          <h2 style="margin-top:30px">{{$root.langText.contacts.contactResult}}</h2>

       
          <div class="form__input" style="margin-top: -20px">
            <p class="form__input__textarea-p" style="min-height: 45px">{{contact_result}}</p>
            <textarea class="form__input__textarea"
              style="min-height: 25px"
              type="text"
              v-model="contact_result"
            />
          </div>


          <div class="form__row" style="justify-content: space-between;overflow: hidden;">
            <span
              style="
                font-size: 12px;
                color: #8eb6e3;
                font-weight: 600;
                line-height: 2.3;
                width: 80%;
              "
              >{{$root.langText.contacts.contactNext}}</span
            >
            <DataInput
              :data="{
                data: dateNext,
                input: 'dateNext',
                mod: 'dataTime',
                min: new Intl.DateTimeFormat('ru').format(new Date()),
              }"
              @resultData="dataInput"
            />
          </div>
        </div>



      </div>







      <div style="overflow-y: auto;max-height: 60vh;" v-if="data.mod == 'history'">

        <div class="form__input form__input--not-focus" v-if="base">
          <input type="text" v-model="base" readonly />
          <span>{{$root.langText.changes.basis}}</span>
        </div>

        <div class="form__input form__input--not-focus">
          <input type="text" v-model="manager" readonly />
          <span>{{$root.langText.input.manager}}</span>
        </div>


        <Autocomplete
          :data="{
            name: $root.langText.contacts.contactPerson,
            mod: 'contact',
            key: 'contact',
            input: contact,
            inputId: contact_id,
            idClient: idClient,
            typeClient: 'client',
          }"
          @result="autocompleteResult"
        />

        <div class="form__input">
          <select v-model="typeTaskContact">
            <option
              v-for="(type, index) in typeTaskContactList"
              :key="index"
              :value="type.id"
            >
              {{ type.name }}
            </option>
          </select>
          <span>{{$root.langText.contacts.contactsView}}</span>
          <div class="icon icon-arrow-down"></div>
        </div>


        <div class="form__input">
          <p class="form__input__textarea-p">{{ description }}</p>
          <textarea class="form__input__textarea" :style="error ? 'border-color:#FF3300': ''" v-model="description" />
          <span :style="error ? 'color:#FF3300': ''">{{$root.langText.result}}</span>
        </div>


        <div class="form__row" style="justify-content: space-between;overflow: hidden;">
          <span
            style="
              font-size: 12px;
              color: #8eb6e3;
              font-weight: 600;
              line-height: 2.3;
              width: 80%;
            "
            >{{$root.langText.contacts.contactNext}}</span
          >
          <DataInput
            :data="{
              data: date,
              input: 'date',
              mod: 'dataTime',
              min: new Intl.DateTimeFormat('ru').format(new Date()),
            }"
            @resultData="dataInput"
          />
        </div>
        

      </div>


      <div class="pop-up__btn" style="align-items:center;justify-content: flex-end;">

        <div class="form__checkbox" v-if="!btn && data.mod == 'tasks'" style="margin: 15px 0 0;width: 100%">
          <label>
            <input 
              type="checkbox"
              v-model="finished"
            >
            <span>{{$root.langText.done}}</span>
          </label>
        </div>
        <a class="btn-save btn btn-primary" href="#" @click.prevent="sendForm"
          >{{$root.langText.save}}</a
        >
      </div>
    </div>
  </div>
</template>



<style lang="scss">
  .title__plus{
    position: absolute;
    right: 10px;
    top: -7px;
    background: #FFFFFF;
    border: 1px solid #C3DBF6;
    box-sizing: border-box;
    box-shadow: 4px 4px 10px rgba(142, 182, 227, 0.3), -4px -4px 10px rgba(195, 219, 246, 0.3);
    border-radius: 50%;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 25px;
    color: #C3DBF6;
  }
</style>


<script>
import DataInput from "@/components/app/DataInput";
import Autocomplete from "@/components/app/Autocomplete";
import Team from "@/components/selection/Team"

export default {
  props: ["data", "idClient"],

  data: () => ({
    creator: "",
    manager: '',
    base: "",
    typeTask: '',
    typeTaskContact: '',
    contact: "",
    contact_id: "",
    date: "",
    description: "",
    contact_result: '',
    dateNext: '',
  
    error: false,
    finished: false,
    btn: false,
    list: [],
    titleFact: '',
    typeTaskList: [],
    typeTaskContactList: [],
    team: false
  }),

  async created() {

    if(this.data.mod == 'tasks'){
      this.creator = this.data.info.creator_name
      this.base = this.data.info.base_text
      this.typeTask = this.data.info.typeID
      this.typeTaskContact = this.data.info.contact_method_type_id
      this.contact = this.data.info.cont_person_name
      this.contact_id = this.data.info.contPerson_id
      this.date = this.data.info.taskDateFrom
      this.description = this.data.info.description

      this.list = this.data.info.members

    } else{
      this.base = this.data.info.base_text
      this.manager = this.data.info.manager_name
      this.contact = this.data.info.cont_person_name
      this.contact_id = this.data.info.contPerson_id
      this.typeTaskContact = this.data.info.type_contact
      this.description = this.data.info.description
      this.date = this.data.info.date_next_contact
    }




    var res = await this.$store.dispatch("getTasksTypes");
    this.typeTaskList = res.data.list;

    var res = await this.$store.dispatch("getTasksContactsTypes");
    this.typeTaskContactList = res.data.list;

    var app = this
    this.typeTaskContactList.forEach(function(item, index){
      if(item.id == app.data.info.type_contact){
        app.titleFact = item.name
      }
    });
    this.typeTaskList.forEach(function(item, index){
      if(item.id == app.data.info.typeID){
        if(item.show_fact_button == '1'){
          app.btn = true
        }
      }
    });
  },

  methods: {
    //date
    dataInput(data) {
      this[data.input] = data.date;
    },

    //autocomplete
    autocompleteResult(data) {
      this[data.input] = data.name;
      this[data.input + "_id"] = data.id;
    },
    closeList(data){
      this.team = false
      var double = true
      if(data){
        this.list.forEach(function(item, index){
          if(data.id == item.manager_id){
            double = false
          }
        })
        if(double){
          this.list.push({manager_name: data.name, manager_id: data.id});
        }
      }
    },
    changeType(data){
      var app = this
      this.typeTaskList.forEach(function(item, index){
        if(item.id == data){
          if(item.show_fact_button == '1'){
            app.btn = true
          } else{
            app.btn = false
          }
        }
      });
    },


    async sendForm(){

      if(this.data.mod == 'tasks'){
        var data = {
          id: this.data.info.id,
          client_id: this.data.info.clientID,
          type_id: this.typeTask,
          contact_method_type_id: this.typeTaskContact,
          contPerson_id: this.contact_id,
          description: this.description,
          finished: this.finished ? '1':'0',
          contact_result: this.contact_result || '',
          members: []
        }
        this.list.forEach(function(item, index){
          data.members.push({member_id: item.manager_id, member_name: item.manager_name})
        })

        data.date_from = (new Date(this.date).getTime() / 1000).toFixed(0) == 'NaN' ? '' : (new Date(this.date).getTime() / 1000).toFixed(0)
        if(this.btn){
          data.date_next_contact = (new Date(this.dateNext).getTime() / 1000).toFixed(0) == 'NaN' ? '' : (new Date(this.dateNext).getTime() / 1000).toFixed(0)
        }


        if(this.description){
          this.error = false
          var res = await this.$store.dispatch("updateTasks", data);
          this.$emit('close')
        } else{
          this.error = true
        }
      } else{
        var data = {
          id: this.data.info.owner_id,
          client_id: this.data.info.client_id,
          contact_person: this.contact_id,
          contact_method_type_id: this.typeTaskContact,
          description: this.description,
          date_next_contact: (new Date(this.date).getTime() / 1000).toFixed(0) == "NaN" ? '' : (new Date(this.date).getTime() / 1000).toFixed(0),
        }

        if(this.description){
          this.error = false
          var res = await this.$store.dispatch("updateContacts_hist", data);
          this.$emit('close')
        } else{
          this.error = true
        }
      } 
    },


  },

  components: {
    DataInput,
    Autocomplete: () => import("@/components/app/Autocomplete.vue"),
    Team,
  },
};
</script>